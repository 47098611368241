import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home.vue'),
    meta: {
      title: 'Home Hiper Usera - Calidad, Precio y Tú',
      description: 'Entérate de todas las promociones, precios y recetas a través de nuestra web. Hiper Usera te cuida.'
    }
  },

  //Static
  {
    path: '/politica-privacidad',
    name: 'politica_privacidad',
    component: () => import('@/views/politica-privacidad.vue')
  },
  {
    path: "/aviso-legal",
    name: 'aviso_legal',
    component: () => import('@/views/aviso-legal.vue')
  },
  {
    path: '/politica-cookies',
    name: 'politica_cookies',
    component: () => import('@/views/politica-cookies.vue')
  },
  {
    path: '/mapa-web',
    name: 'mapa_web',
    component: () => import('@/views/mapa-web.vue')
  },
  {
    path: '/sobre-nosotros',
    name: 'sobre_nosotros',
    component: () => import('@/views/sobre-nosotros.vue'),
    meta: {
      title: "Sobre Nosotros Hiper Usera - Más de 35 Años a tu Lado",
      description: 'En Hiper Usera cuidamos los procesos para unos resultados ecológicos y sostenibles. Descubre nuestra historia, misión, visión y valores.'
    }
  },
  {
    path: '/historia',
    name: 'historia',
    component: () => import('@/views/historia.vue'),
    meta: {
      title: 'Historia Hiper Usera - 35 Años a tu Lado',
      description: 'En Hiper Usera llevamos 35 años el mejor equilibrio entre calidad y precio.'
    }

  },
  {
    path: '/terminos-y-condiciones',
    name: 'terminos_y_condiciones',
    component: () => import('@/views/terminos-y-condiciones.vue')
  },
  {
    path: '/codigo-etico-sistema-interno-de-informacion-y-politica-de-cumplimiento',
    name: 'codigo-etico',
    component: () => import('@/views/codigo-etico.vue')
  },

  {
    path: '/darse-de-baja',
    name: 'darse_de_baja',
    component: () => import('@/views/darse-de-baja.vue')
  },

  //Te cuida
  {
    path: '/te-cuida',
    name: 'te_cuida',
    component: () => import('@/views/te-cuida.vue'),
    meta: {
      title: 'Hiper Usera - Te Cuida - Todo lo Hacemos Desde el Corazón',
      description: 'En Hiper Usera estamos comprometidos con la mejora continua, la responsabilidad social y contigo.'
    }
  },
  {
    path: '/te-cuida/cuidamos-el-planeta',
    name: 'cuidamos_el_planeta',
    component: () => import('@/views/cuidamos-el-planeta.vue'),
    meta: {
      title: 'Hiper Usera - Te Cuida - Cuidamos el Planeta',
      description: 'Invertimos en nuestras instalaciones, nos movemos por el medioambiente, sustituimos el plásticos, ayudamos al planeta.'
    }
  },
  {
    path: '/te-cuida/cuidamos-tu-alimentacion',
    name: 'cuidamos_tu_alimentacion',
    component: () => import('@/views/cuidamos-tu-alimentacion.vue'),
    meta: {
      title: 'Hiper Usera - Te Cuida - Cuidamos tu Alimentación',
      description: 'Cuidamos de tu alimentación garantizando los mejores productos y los frescos de mayor calidad, así como ayudándote junto a nuestra chef a preparar recetas rápidas y sencillas.'
    }
  },
  {
    path: '/te-cuida/ayuda-social',
    name: 'ayuda_social',
    component: () => import('@/views/ayuda-social.vue'),
    meta: {
      title: 'Hiper Usera - Te Cuida - Ayuda Social',
      description: 'En Hiper Usera colaboramos en la lucha contra el cáncer, realizamos pequeñas donaciones y ayudamos en la inserción laboral.'
    }
  },
  {
    path: '/te-cuida/apoyamos-deporte',
    name: 'apoyamos_deporte',
    component: () => import('@/views/apoyamos-deporte.vue'),
    meta: {
      title: 'Hiper Usera - Te Cuida - Apoyamos el Deporte',
      description: 'No paramos de hacer donaciones a diferentes asociaciones y ONGs, participar en proyectos solidarios y ofrecer todo lo que esté a nuestra disposición.'
    }
  },

  //Contact
  {
    path: '/contacto',
    name: 'contacto',
    component: () => import('@/views/contacto.vue'),
    meta: {
      title: 'Hiper Usera - Contacto - Solicita Información',
      description: 'Contacta con nosotros y nos pondremos en contacto contigo lo antes posible. Estaremos encantados de ayudarte.'
    }
  },
  {
    path: '/ofertas-de-trabajo',
    name: 'ofertas',
    component: () => import('@/views/ofertas.vue')
  },
  {
    path: '/trabaja-con-nosotros/:id?',
    name: 'trabaja',
    component: () => import('@/views/trabaja-con-nosotros.vue'),
    meta: {
      title: 'Hiper Usera - Trabaja con Nosotros - Únete a Nuestro Equipo',
      description: 'En Hiper Usera trabajamos cada día para crear un futuro mejor. Para ellos nos apoyamos en el talento de las personas. Si quieres trabajar con nosotros, rellena nuestro formulario.'
    }
  },

  //Shops
  {
    path: '/mercado/:id?',
    name: 'mercado',
    component: () => import('@/views/mercado.vue'),
    meta: {
      title: 'Hiper Usera - Mercado: Pescadería, Carnicería, Charcutería, Frutería y Panadería',
      description: 'En Hiper Usera somos especialistas en productos frescos. Descubre nuestras secciones de pescadería, carnicería, charcutería, frutería y panadería'
    }
  },
  {
    path: '/cash-mayoristas',
    name: 'cash_mayoristas',
    component: () => import('@/views/cash-mayoristas.vue'),
    meta: {
      title: 'Hiper Usera - Profesionales: Cash Mayoristas',
      description: 'Realiza una compra adaptada a tus necesidades. Hacemos posible que los hosteleros y profesionales de la alimentación dispongan de una mayor eficacia en el ejercicio de su trabajo.'
    }

  },
  {
    path: '/nuestra-sala',
    name: 'nuestra_sala',
    component: () => import('@/views/nuestra-sala.vue'),
  },
  {
    path: '/nuestras-tiendas',
    name: 'nuestras_tiendas',
    component: () => import('@/views/nuestras-tiendas.vue'),
    meta: {
      title: 'Hiper Usera - Nuestras Tiendas',
      description: 'Descubre que servicios ofrecemos en Hiper Usera. Parkin, reparto a domicilio, comodidad, cercanía y mucho más.'
    }
  },

  //Entries
  {
    path: '/noticias',
    name: 'noticias',
    component: () => import('@/views/noticias.vue'),
    meta: {
      title: 'Hiper Usera - Descubre Nuestras Noticias: Ayuda Social, Reformas, Cash, Supermercados',
      description: 'Mantente al día de las novedades de Hiper Usera. Compromisos, cuidado del planeta, reformas, supermercados, cash, te cuida, deportes y aperturas.'
    }

  },
  {
    path: '/noticias/:slug',
    name: 'noticia',
    props: true,
    component: () => import('@/views/noticia.vue'),
    meta: {
      title: 'Hiper Usera - Descubre Nuestras Noticias: Ayuda Social, Reformas, Cash, Supermercados',
      description: 'Mantente al día de las novedades de Hiper Usera. Compromisos, cuidado del planeta, reformas, supermercados, cash, te cuida, deportes y aperturas.'
    }

  },

  //Recipes
  {
    path: '/recetas',
    name: 'recetas',
    component: () => import('@/views/recetas.vue'),
    meta: {
      title: 'Hiper Usera - Descubre Nuestras Recetas: Ensaladas, Legumbres, Postres, Arroces, Verduras y más',
      description: 'Te damos ideas para cocinar los alimentos que compras en nuestras tiendas. Ensaladas, legumbres, cremas, arroces, verduras, sandwiches bocadillos hortalizas y más.'
    }

  },
  {
    path: '/recetas/:slug',
    name: 'receta',
    props: true,
    component: () => import('@/views/receta.vue'),
    meta: {
      title: 'Hiper Usera - Descubre Nuestras Recetas: Ensaladas, Legumbres, Postres, Arroces, Verduras y más',
      description: 'Te damos ideas para cocinar los alimentos que compras en nuestras tiendas. Ensaladas, legumbres, cremas, arroces, verduras, sandwiches bocadillos hortalizas y más.'
    }
  },

  //Offers
  {
    path: '/promociones',
    name: 'promociones',
    component: () => import('@/views/promociones.vue'),
    meta: {
      title: 'Hiper Usera - No te Pierdas Nuestras Promociones',
      description: 'En Hiper Usera siempre encontrarás la mejor relación precio y calidad. Descubre que ofertas destacamos este mes en cada una de nuestras secciones.'
    }
  },
  {
    path: '/:pathMatch(.*)',
    redirect: function () {
      return {
        path: '/',
        query: {
          httpstatus: 404,
        }
      }
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
      }
    }
    return {
      top: 0
    }
  },
})


router.beforeEach((to, from, next) => {

  let meta = to.meta
  document.head.querySelector("[property='og:url']").content = location.href;
  if (!!meta.title && !!meta.description) {
    document.title = meta.title
    document.head.querySelector("[property='og:title']").content = meta.title;
    document.head.querySelector("[property='og:description']").content = meta.description;
    document.head.querySelector("[name=description]").content = meta.description;
  } else {
    document.title = 'Hiper Usera - Calidad, Precio y Tú';
    document.head.querySelector("[property='og:title']").content = 'Home Hiper Usera - Calidad, Precio y Tú';
    document.head.querySelector("[property='og:description']").content = 'Entérate de todas las promociones, precios y recetas a través de nuestra web. Hiper Usera te cuida.';
    document.head.querySelector("[name=description]").content = 'Entérate de todas las promociones, precios y recetas a través de nuestra web. Hiper Usera te cuida.';
  }
  if (to.query.httpstatus) {
    let metaComponent = document.createElement('meta');
    metaComponent.name = "prerender-status-code";
    metaComponent.content = to.query.httpstatus;
    document.head.appendChild(metaComponent);
    if (to.query.httpstatus == 301) {
      metaComponent = document.createElement('meta');
      metaComponent.name = "prerender-header";
      metaComponent.content = "Location: " + location.href;
      document.head.appendChild(metaComponent);
    }
    delete to.query.httpstatus;
  }
  next()
});
export default router
