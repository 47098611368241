<template>
    <header class="app-header" :nothastop="store.headers.length == 0">
        <div class="top" v-if="store.headers.length != 0">
            <carousel :items-to-show="1">
                <slide :key="key" v-for="(header,key) in store.headers" :autoplay="2000" :wrapAround="true">
                    <h3>{{header.title}} <a :href="header.url">{{header.cta}}</a> {{header.message}}</h3>
                </slide>
            </carousel>
        </div>

        <div class="bottom row-between ais">
            <div class="contenedor-logo">
                <router-link :to="{ name: 'home' }">
                    <img src="@/assets/img/logo-hiperusera.svg" alt="Hiperusera">
                </router-link>
            </div>
            <nav class="row-center" :show="showmenu">
                <button class="close-menu" @click="showmenu = false" arial-label="close-menu">✕</button>
                <router-link :to="{name : 'sobre_nosotros'}">Sobre nosotros</router-link>
                <router-link :to="{name : 'nuestras_tiendas'}">Nuestras Tiendas</router-link>
                <router-link :to="{name : 'mercado'}">El mercado</router-link>
                <router-link :to="{name : 'promociones'}">Promociones</router-link>
                <router-link :to="{name : 'contacto'}">Contacto</router-link>
                <router-link :to="{name : 'te_cuida'}" class="te-cuida row-center">
                    <img width="55" src="@/assets/img/logo-te-cuida.png" alt="Te cuida">
                    Te cuida
                </router-link>
            </nav>
            <button class="show-menu" @click="showmenu = !showmenu" arial-label="show-menu">
                <span></span><span></span><span></span>
            </button>
        </div>
    </header>
</template>


<script>
    import { offersStore } from '@/store/offers'
    import { Carousel, Slide} from 'vue3-carousel';
    export default {
        name: 'app-header',
        components: {
            Carousel,
            Slide,
        },
        data() {
            const oStore = offersStore();
            return {
                showmenu: false,
                store: oStore,
            };
        },
        mounted()
        {
            this.store.loadHeaders();
        },
        watch: {
            '$route'() {
                this.showmenu = false;
            }
        },
    }
</script>