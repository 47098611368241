import { createApp } from 'vue'
import { createPinia } from 'pinia';
import './registerServiceWorker'

import axios from 'axios'
import VueAxios from 'vue-axios'

import dayjs from 'dayjs'
require('dayjs/locale/es')

import VueGtag from 'vue-gtag'

import {LoadingPlugin} from 'vue-loading-overlay';

import './vee-validate'

import router from './router'
import App from './App.vue'


axios.defaults.baseURL = process.env.VUE_APP_BACK;
dayjs.locale('es');

export const pinia = createPinia();

const app = createApp(App);
app.use(pinia)
app.use(VueAxios, axios)
app.use(router)
app.use(LoadingPlugin)

if (process.env.VUE_APP_GOOGLE_ID) {
    app.use(VueGtag, {
        config: {
            id: process.env.VUE_APP_GOOGLE_ID
        }
    }, router);
}
app.config.globalProperties.dayjs = dayjs;
app.mount('#app');
export default app;
