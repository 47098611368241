<template>
    <footer>
        <div class="top">
            <div class="container row-center">
                <div class="left">
                    <div class="links row-start ais">
                        <div>
                            <p class="strong">Profesionales</p>
                            <router-link :to="{name:'cash_mayoristas'}">Cash mayoristas</router-link>
                        </div>
                        <div>
                            <p class="strong">Trabaja con nosotros</p>
                            <router-link :to="{name:'trabaja'}">Únete a nuestro equipo</router-link>
                        </div>
                        <div>
                            <p class="strong">Al día</p>
                            <router-link :to="{name:'noticias'}">Últimas noticias</router-link>
                            <router-link :to="{name:'recetas'}">Recetas</router-link>
                        </div>
                    </div>
                    <router-link to="/codigo-etico-sistema-interno-de-informacion-y-politica-de-cumplimiento" class="link-border">Código Ético, Sistema Interno de Información y Política de Cumplimiento</router-link>
                </div>
                <div class="right">
                    <div class="link-app">
                        <h2>DESCARGA NUESTRA APP</h2>
                        <div class="container-links">
                            <a href="https://apps.apple.com/es/app/hiper-usera-y-t%C3%BA/id6443568063" target="_blank">
                                <img src="@/assets/img/apple_store.svg" alt="">
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.comerzzia.hiperusera" target="_blank">
                                <img src="@/assets/img/google_play.svg" alt="">
                            </a>
                        </div>
                    </div>
                    <div class="rrss">
                        <h2>#hiperuseratecuida</h2>
                        <nav class="row-start">
                            <a class="facebook" target="_blank"  href="https://www.facebook.com/Hiper_Usera_Oficial/">
                                <img src="@/assets/img/facebook.svg" alt="Facebook">
                            </a>
                            <a class="instagram" target="_blank" href="https://www.instagram.com/hiperusera_oficial/">
                                <img src="@/assets/img/instagram.svg" alt="Instagram">
                            </a>
                            <a class="twitter" target="_blank" href="https://twitter.com/hiper_usera">
                                <img  src="@/assets/img/twitter.svg" alt="Twitter">
                            </a>
                            <a class="linkedin" target="_blank" href="https://www.linkedin.com/company/hiper-usera/ ">
                                <img src="@/assets/img/linkedin.svg" alt="Linkedin">
                            </a>
                            <a class="facebook" target="_blank" href="https://www.youtube.com/channel/UCnIoKR-cOAyZInN3Ze2haNg">
                                <img src="@/assets/img/youtube.svg" alt="Youtube">
                            </a>
                            <a class="Tiktok" target="_blank" href="https://www.tiktok.com/@hiper_usera">
                                <img src="@/assets/img/tiktok.svg" alt="Tiktok">
                            </a>
                        </nav>
                    </div>

                </div>

            </div>

        </div>

        <div class="bottom">
            <div class="container row-between">
                <p>© 2022 HIPER USERA S.L. Todos los derechos reservados.</p>
                <nav class="row-start">
                    <router-link :to="{ name: 'mapa_web' }">Mapa web</router-link>
                    <router-link :to="{ name: 'aviso_legal' }">Aviso legal</router-link>
                    <router-link :to="{ name: 'politica_privacidad' }">Política de Protección de Datos</router-link>
                    <router-link :to="{ name: 'politica_cookies' }">Política de cookies</router-link>
                </nav>
            </div>
        </div>

    </footer>
</template>

<script>
    export default {
        name: 'app-footer'
    }
</script>