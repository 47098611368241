<template>
  <app-header v-if="$route.name != 'historia'"></app-header>
  <router-view />
  <app-footer v-if="$route.name != 'historia'"></app-footer>

  
</template>
<script>
  import header from '@/components/header';
  import footer from '@/components/footer';
  export default {
    name: 'app',
    components: {
      'app-header': header,
      'app-footer': footer
    },
    
  }
</script>


<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Heebo:wght@300;500;700&family=Literata:opsz,wght@7..72,400;7..72,700&display=swap');
  @import '@/assets/css/colors.scss';
  @import '@/assets/css/style.scss';
  @import '@/assets/css/responsive.scss';
  @import 'vue3-carousel/dist/carousel.css';

  @import "splitting/dist/splitting.css";
  @import "splitting/dist/splitting-cells.css";
  @import 'vue-loading-overlay/dist/css/index.css';
</style>