import { defineRule, configure } from 'vee-validate';
import * as rules from '@vee-validate/rules';
import { setLocale,localize } from '@vee-validate/i18n';
import fields from '@/locales/es/fields.json';
import validations from '@/locales/es/validations.json';


Object.keys(rules).forEach(rule => {
    if (rule == "default" || rule == "all") {
        //..yeah...kinda funky...
        return;
    }
    defineRule(rule, rules[rule]);
});

configure({
    generateMessage: localize({
        es: {
            messages: validations,
            names: fields
        }
    })
})
setLocale('es');