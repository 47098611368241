import axios from 'axios'
import { defineStore } from 'pinia';

export const offersStore = defineStore('offers', {
    state: () => {
        return {
            offers: [],
            offersFeatured: [],
            headers: [],
            pamplhets: [],
        }
    },
    actions: {
        loadOffersFeatured() {
            if (this.offersFeatured && this.offersFeatured.length > 0) {
                return;
            }
            let data = {
                filters: [
                    {
                        name: "featured",
                        search: 1,
                    }
                ],
                order: [
                    {
                        name: "publish_date",
                        direction: "desc"
                    }
                ],
            }
            return new Promise((resolve) => {
                axios.post('offers', data).then(r => {
                    this.offersFeatured = r.data.data;
                    resolve(r);
                })
            });
        },
        loadOffers() {
            if (this.offers && this.offers.length > 0) {
                return;
            }
            let data = {
                order: [
                    {
                        name: "publish_date",
                        direction: "desc"
                    }
                ],
            }
            return new Promise((resolve) => {
                axios.post('offers', data).then(r => {
                    this.offers = r.data.data;
                    resolve(r);
                })
            });
        },
        loadHeaders() {
            if (this.headers && this.headers.length > 0) {
                return;
            }
            return new Promise((resolve) => {
                axios.post('headers').then(r => {
                    this.headers = r.data.data;
                    resolve(r);
                });
            });
        },
        loadPamplhets() {
            let data = {
                "order": [
                    {
                        name: 'publish_date',
                        direction: 'desc'
                    }
                ],
            }
            if (this.pamplhets && this.pamplhets.length > 0) {
                return;
            }
            return new Promise((resolve) => {
                axios.post('pamphlets', data).then(r => {
                    this.pamplhets = r.data.data;
                    resolve(r);
                })
            });
        },
    }
})